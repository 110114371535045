/*! =========================================================
 *
 Material Dashboard PRO React - v1.5.0 based on Material Dashboard PRO - V1.2.1
*
* =========================================================
*
* Copyright 2016 Creative Tim (https://www.creative-tim.com/product/material-dashboard-pro-react)
 *
 *                       _oo0oo_
 *                      o8888888o
 *                      88" . "88
 *                      (| -_- |)
 *                      0\  =  /0
 *                    ___/`---'\___
 *                  .' \|     |// '.
 *                 / \|||  :  |||// \
 *                / _||||| -:- |||||- \
 *               |   | \\  -  /// |   |
 *               | \_|  ''\---/''  |_/ |
 *               \  .-\__  '-'  ___/-. /
 *             ___'. .'  /--.--\  `. .'___
 *          ."" '<  `.___\_<|>_/___.' >' "".
 *         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *         \  \ `_.   \_ __\ /__ _/   .-` /  /
 *     =====`-.____`.___ \_____/___.-`___.-'=====
 *                       `=---='
 *
 *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *               Buddha Bless:  "No Bugs"
 *
 * ========================================================= */

// variables and mixins
@import "site/variables";
@import "site/mixins";
@import "site/shadows";

// plugin css
@import "site/plugins/plugin-nouislider";
@import "site/plugins/plugin-perfect-scrollbar";
@import "site/plugins/plugin-react-datetime";
@import "site/plugins/plugin-react-bootstrap-sweetalert";
@import "site/plugins/plugin-react-chartist";
@import "site/plugins/plugin-react-big-calendar";
@import "site/plugins/plugin-react-jvectormap";
@import "site/plugins/plugin-react-table";
@import "site/plugins/plugin-react-tagsinput";

// Core CSS
@import "site/misc";
@import "site/fileupload";
@import "site/fixed-plugin";
